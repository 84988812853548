<!--자동 선제안 관리 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="inline-block w-1/4 mr-8">
				<h6>제안일</h6>
				<div class="flex">
					<b-form-radio class="w-20" v-model="opt.period" name="searchRadioPeriod" value="">
						<span class="mr-2">전체</span>
					</b-form-radio>
					<b-form-radio v-model="opt.period" name="searchRadioPeriod" value="period" />
					<div class="flex -mt-1.5">
						<datePicker class="" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker class="" model="opt.endDate" />
					</div>
				</div>
			</div>
			<div class="inline-block w-1/6">
				<h6>상태</h6>
				<div class="flex">
					<b-form-checkbox
						v-for="v in stateOpts"
						class="mr-4"
						v-model="opt.state"
						:value="v.value"
						color="primary"
						>{{ v.label }}
					</b-form-checkbox>
				</div>
			</div>
			<div class="inline-block w-1/10 pr-8">
				<h6>제안결과</h6>
				<vSelect class="" v-model="opt.result" :clearable="false" :options="resultOpts" />
			</div>
			<div class="inline-block w-5/12">
				<h6 class="w-1/1">&nbsp;</h6>
				<div class="flex">
					<v-select class="w-80" v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
					<b-form-input
						class="w-1/1 mx-4"
						v-model.trim="opt.searchKeyword"
						name="keyword"
						placeholder="검색어"
						@keyup.enter="search"
					/>
					<b-button class="w-40 lh-3" @click="search">검색</b-button>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<div class="absolute right-0">
				<b-button class="mr-4" @click="proposalDealListPopup.open(true)" variant="orange"
					>오늘 제안 업로드</b-button
				>
				<b-button class="mr-4" @click="proposalDealListPopup.open(false)">주간 제안 업로드</b-button>
				<b-button @click="excelExport">엑셀 다운로드 요청</b-button>
			</div>
			<tb class="mt-14" :inf="inf" :isStriped="false" :res="res">
				<template #proposalTb="{ h, v }">
					<table class="table b-table proposalInnerTable mb-0">
						<tbody>
							<tr v-for="item in v.proposalItems">
								<td
									v-for="i in h.colspanCt"
									:class="`w-${inf[h.colspanStart * 1 + i - 1].size} ${
										inf[h.colspanStart * 1 + i - 1].notCenter ? 'text-left' : 'text-center'
									}`"
								>
									{{ item[inf[h.colspanStart * 1 + i - 1].key] }}
								</td>
							</tr>
						</tbody>
					</table>
				</template>
			</tb>
		</b-card>
		<proposalDealListPopup :pr="ths" cName="proposalDealListPopup" />
	</div>
</template>

<script>
import proposalDealListPopup from 'pages/proposalDealListPopup'

const now = new Date(),
	resultOpts = [
		{ label: '전체', value: 'all' },
		{ label: '미확인', value: 'unread' },
		{ label: '확인', value: 'read' },
		{ label: '거절', value: 'denied' },
		{ label: '핫딜 확정', value: 'accept' },
		{ label: '핫딜 취소', value: 'cancel' },
	],
	stateOpts = [
		{ label: '제안중', value: 'inProposal' },
		{ label: '마감', value: 'finished' },
		{ label: '대기', value: 'waiting' },
	],
	searchKeyOpts = [
		{ label: '핫트아이디', value: 'hottId' },
		{ label: '상품그룹 idx', value: 'productGroupIdx' },
		{ label: '상품그룹 이름', value: 'productGroupName' },
	],
	defOpt = {
		//period: 'period',
		period: '',

		startDate: now,
		endDate: new Date(now.getFullYear(), now.getMonth(), now.getDate() + 14),

		state: [stateOpts[0].value, stateOpts[1].value],

		result: resultOpts[0],
		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}
//검색조건 기본값. 검색 조건 초기화를 위해 사용 opt로 치환됨

export default {
	components: { proposalDealListPopup },
	data() {
		return {
			resultOpts,
			stateOpts,
			searchKeyOpts,
			opt: { ...defOpt },
			defOpt,
			lastOpt: {},
			res: {},
			inf: [
				{ title: 'No', model: 'idx', size: 2 },
				{ title: '아이디', key: 'hottId', size: 3 },
				{ title: '이름', key: 'memberName', size: 2 },
				{ title: '인스타 아이디', key: 'currentInstaId', model: 'insta', size: 4 },
				{
					title: '제안링크',
					key: 'proposalLink',
					size: 5,
					notCenter: true,
					model: 'url',
					copyBtn: 1,
					func: item => layout.copyText(item.proposalLink, '제안링크가 복사되었습니다.', 500),
				},
				{ title: '상태', key: 'status', size: 2 },
				{ title: '제안일', key: 'proposalDate', size: 3 },
				{ title: '오늘 제안', key: 'proposalType', size: 1 },
				{
					title: '상품순서',
					model: 'proposalTb',
					key: 'displayOrder',
					size: 2,
					colspanStart: 8,
					colspanCt: 5,
					tdClass: 'p-0',
				},
				{ title: 'idx', key: 'productGroupIdx', size: 2 },
				{ title: '제안상품', key: 'productGroupName', size: 14, notCenter: 1 },
				{ title: '제안결과', key: 'result', size: 4 },
				{ title: '생성일', key: 'createDt', size: 4 },
				{ title: '메모', model: 'memoBtn', domain: 'MEMBER', size: 2 },
			],
		}
	},
	methods: {
		search() {
			//검색 시작
			const opt = this.opt,
				startDate = getDateValue(opt.startDate),
				endDate = getDateValue(opt.endDate),
				result = getDropdownValue(opt.result),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					paging: getPaging(),
					isExcel: 0,
					isWholeDate: opt.period != 'period',
					status: opt.state.filter(v => v),
					result,
				},
				//선택 조건에 없는 필수값들 입력
				error = []

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (opt.period == 'period') {
				if (startDate > endDate) error.push('제안일의 시작일이 종료일보다 큽니다.')
				else {
					data.startDate = startDate.substr(0, 10).replace(/-/g, '')
					data.endDate = endDate.substr(0, 10).replace(/-/g, '')
				}
			}

			if (searchVal) data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal)
			if (error.length) {
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.e(error)
				return false
			} else {
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				return this.changePage(1)
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('api/backoffice/dealProposalSelect/proposalDealList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					//데이터가 바뀔 때마다 처음에 한 번만 포매팅이 필요
					res.list = res.list.map((v, i) => {
						v.createDt = v.createDt ? v.createDt.replace('T', ' ') : ''

						//상품순서~생성일까지 colspan 적용
						v.displayOrderOpt = { colspan: 5 }
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel()
			if (!opt.paging) {
				alert.w('검색을 먼저 해주세요')
				return
			}

			ex.date(opt.startDate, opt.endDate, '제안일')

			const status = opt.status.map(v => getOptsFromValue(stateOpts, v).label)
			ex.key('상태', `( ${status.join(', ')} )`)

			ex.dropdown(opt.result, resultOpts, '제안결과')

			ex.search(opt.searchKey, searchKeyOpts)

			ex.go(this)
		},
	},
	mounted() {
		this.search()
	},
}
</script>

<style>
.proposalInnerTable tr:first-child td {
	border-top: none;
}
</style>
