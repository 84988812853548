<template>
	<basePopup :cancelFunc="cancel" :okFunc="save" okText="업로드" title="선제안 데이터 엑셀 업로드">
		<div class="flex">
			<b-form-file
				v-model="input.file"
				placeholder="파일을 여기로 드래그"
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				browse-text="파일 찾기"
			/>
		</div>
		<clipboard :pr="ths" />
	</basePopup>
</template>

<script>
import { uploadFile } from 'libs/axios'
import basePopup from 'comp/local/basePopup'
import clipboard from 'comp/local/clipboard'

export default {
	components: { basePopup, clipboard },
	data() {
		return {
			isShow: false,
			title: '',

			isTodayUpload: false,
			input: {
				file: null,
			},
		}
	},
	methods: {
		open(isTodayUpload = false) {
			this.input.file = null
			this.isShow = true
			this.isTodayUpload = isTodayUpload
			setTimeout(() => this.clipboard.clear())
		},

		save() {
			const input = this.input

			if (!input.file && this.clipboard.e) alert.w(this.clipboard.e)
			else {
				if (this.isTodayUpload) this.todaySave()
				else this.nextWeekSave()
			}
		},

		todaySave() {
			const preFunc = this.input.file
				? uploadFile(this.input.file, 'proposal').then(url => {
						//오늘 제안 파일 업로드는 파일 업로드한 뒤에 검증
						const endOfDomain = url.indexOf('/')
						return {
							bucketName: url.substr(0, endOfDomain),
							fileName: url.substr(endOfDomain + 1),
						}
				  })
				: this.clipboard.getPromise()

			preFunc.then(data => {
				const insertFunc = (isExcludeProposed = false) => {
					postApi(
						'api/backoffice/dealProposalHandle/uploadTodayProposalDealList',
						{ ...data, isExcludeProposed },
						true,
						false
					).then(res => {
						const failedCt = res.message,
							failedList = res.data,
							ct = failedList.length
						let msg = '엑셀 파일이 업로드되었습니다.',
							appendMsg = ''
						if (failedCt) {
							msg += `\r\n제외된 데이터 : ${comma(failedCt)}건`
							if (ct > 10) {
								failedList.splice(10, 9999)
								appendMsg = ' 외'
							}
							msg += '\r\n\r\n제외된 핫티스트(최대 10명만 표기):\r\n' + failedList.join(', ') + appendMsg
						}
						alert.s(msg)
						reload(this, true)
						this.isShow = false
					})
				}
				postApi('api/backoffice/dealProposalSelect/todayProposalDealValidation', data).then(res => {
					if (res.length) {
						let msg = ''
						if (res.length > 10) {
							res.splice(10, 9999)
							msg = ' 외'
						}
						confirm(
							'이미 ‘오늘 제안’으로 제안 중이고 아직 제안을 확인하지 않은 핫티스트가 포함돼있습니다.\r\n이 파일의 데이터로 덮어쓰겠습니까?\r\n‘취소’를 누르면 업로드 중단합니다.\r\n\r\n덮어쓸 핫티스트:\r\n' +
								res.join(', ') +
								msg,
							'',
							'',
							btn => {
								btn[2] = btn[1]
								;[
									{ text: '제외하고 업로드', func: () => insertFunc(true), variant: 'primary' },
									{
										text: '덮어쓰기 업로드',
										func: () => insertFunc(false),
										variant: 'warning',
									},
								].map((v, i) => {
									btn[i] = { ...v, class: 'float-right mr-4 w-1/4' }
								})
							}
						)
					} else insertFunc()
				})
			})
		},
		nextWeekSave() {
			postApi('api/backoffice/dealProposalSelect/alreadyExistNextDealProposal').then(isDup => {
				const func = () => {
					const preFunc = this.input.file
						? uploadFile(this.input.file, 'proposal').then(url => {
								const endOfDomain = url.indexOf('/')
								return {
									bucketName: url.substr(0, endOfDomain),
									fileName: url.substr(endOfDomain + 1),
									adminSeq: layout.user.seqNo,
								}
						  })
						: this.clipboard.getPromise({ adminSeq: layout.user.seqNo })
					preFunc.then(data => {
						postApi('api/backoffice/dealProposalHandle/uploadProposalDealList', data).then(failedDataCt => {
							let msg = '엑셀 파일이 업로드되었습니다.'
							if (failedDataCt) msg += `\r\n제외된 데이터 : ${comma(failedDataCt)}건`
							alert.s(msg)
							reload(this, true)
							this.isShow = false
						})
					})
				}
				if (isDup)
					confirm('이미 업로드된 선제안 데이터가 있습니다. \r\n이 파일의 데이터로 덮어쓰겠습니까?', func)
				else func()
			})
		},
		cancel() {
			if (this.input.file)
				confirm('업로드를 완료하지 않은 파일이 있습니다.\r\n업로드를 취소하시겠습니까?', () => {
					this.isShow = false
				})
			else this.isShow = false
		},
	},
}
</script>
